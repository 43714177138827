<template>
  <main class="main-layout-wrap">
    <div class="content">
      <div class="monitoring-settings bg-white p-24 br-bottom-left-5 br-bottom-right-5">
        <div class="mb-24">
          <h1 class="color-text-darker fs-22 lh-32 fw-600 mb-24">Monitoring</h1>
          <h2 class="color-text-darker fs-16 lh-24 fw-400 mb-16">Subjects being monitored</h2>

          <div class="br-5 overflow-hidden b-all bc-form-border">
            <button
              @click="openCases = !openCases"
              id="select-case"
              v-if="!isCaseSelected"
              class="d-flex align-center jc-space-between color-text-lighter bg-form lh-48 let-space-04 w-100 px-16"
              :class="{ 'b-bottom bc-form-border' : openCases }"
            >
              <span class="fs-14">Select case</span>
              <div class="d-flex align-center color-link">
                <span class="fs-13 fw-600 mr-10">{{ openCases ? 'close' : 'open' }}</span>
                <Icon
                  name="arrow-down"
                  :class="{ 'rotate--180': openCases, 'mt-2': !openCases }"
                />
              </div>
            </button>
            <ul
              v-if="openCases && !isCaseSelected"
              class="cases-list custom-scrollbar bg-form"
            >
              <li
                v-for="(inv, index) in monitoringCases"
                :key="inv.oid"
              >
                <button
                  class="d-flex transition align-center jc-space-between bg-form bg-form-darker_hover bg-form_active w-100 px-16 py-8"
                  :class="{ 'b-top bc-form-border': index !== 0 }"
                  @click="openCaseProfiles(inv)"
                >
                  <div class="d-flex align-center">
                    <div
                      class="w-40px lh-20 b-all-double bc-link-border text-center br-5 color-link fw-600 fs-13"
                    >
                      {{ index + 1 }}
                    </div>
                    <div class="fs-14 lh-24 ml-12">{{ inv.tag }}</div>
                  </div>
                </button>
              </li>
            </ul>
            <div>
              <MonitoringSettingsCase
                :investigation="selectedCase || caseFromParams"
                :profiles="caseProfiles"
                :fromParams="caseFromParams"
                :loadProfiles="loadProfiles"
                v-if="isCaseSelected"
                @removeSelectedCase="removeSelectedCase"
              />
            </div>
          </div>
        </div>
        <h2 class="color-text-darker fs-16 lh-24 fw-400 mb-16">Settings</h2>
        <div class="d-flex align-center jc-space-between color-text-darker mb-12">
          <label
            for="profile-changes"
            class="fs-14 lh-24 let-space-04"
          >
            Changes in profile information (Avatar, Name, Place of work, education, etc.)
          </label>
          <div class="d-flex align-center">
            <label
              for="profile-changes"
              class="fs-14 lh-24 let-space-04 color-link mr-12"
            >
              {{ profileChangesPrice }} slp
            </label>
            <input
              id="profile-changes"
              type="checkbox"
              class="profile-card-checkbox"
              :checked="checkProfileInformationChanges"
              @change="checkProfileInformationChanges = !checkProfileInformationChanges"
            >
            <label for="profile-changes" class="profile-card-checkbox__label"></label>
          </div>
        </div>
        <!-- <div class="monitoring-settings__input-block">
          <label for="friends-changes">
            Changes to the subject’s social graph (additions / removals of friends / followers / followings)
          </label>
          <label for="friends-changes">{{ friendsChangesPrice }} slp</label>
          <input
            id="friends-changes"
            type="checkbox"
            class="profile-card-checkbox"
            :checked="checkFriendsListChanges"
            @change="checkFriendsListChanges = !checkFriendsListChanges"
          >
          <label for="friends-changes" class="profile-card-checkbox__label"></label>
        </div> -->
        <div class="d-flex align-center jc-space-between fs-16 lh-24 let-space-04 mt-24">
          <div class="color-text-darker">Step</div>
          <div>1 iteration per {{ period }}</div>
        </div>
        <div class="pseudo-range">
          <div class="pseudo-body">
            <div class="pseudo-line" :style="{ width: (100 / 4) * step + '%' }"></div>
            <div class="pseudo-bullet" :style="{ left: (100 / 4) * step + '%' }"></div>
          </div>
          <input id="iteration-range" type="range" step="1" min="0" max="4" v-model="step">
        </div>
        <div class="d-flex align-center jc-space-between">
          <button
            id="monitoring-back"
            class="bttn-transparent w-260px"
            @click="goBack"
          >
            <Icon name="arrow-left" class="mr-14"></Icon>
            <span>Back</span>
          </button>
          <div class="d-flex align-center">
            <p class="fs-16 let-space-04 color-text-lighter mr-32">{{ totalPrice }} slp per {{ period }}</p>
            <button
              id="start-monitoring"
              class="bttn-primary w-260px"
              @click="startMonitoring"
              :disabled="!totalPrice || lowSlp"
            >
              {{ editOneCase ? 'Edit Step' : 'Start monitoring' }}
            </button>
          </div>
        </div>
        <div
          v-if="lowSlp && lowSlpInfo"
          class="bg-form br-5 px-24 py-16 mt-24"
        >
          <div class="d-flex jc-space-between mb-12">
            <div class="fs-16 fw-400 color-link lh-24">Your balance is not enough to start monitoring</div>
            <button
              @click="lowSlpInfo = false"
              class="lh-24"
            >
              <Icon name="cancel-btn" />
            </button>
          </div>
          <div class="d-flex jc-space-between">
            <div class="fs-14 lh-24 ">You can buy more SLP.</div>
            <div>
              <router-link
                :to="'/plan'"
                class="fs-14 color-link color-link-lighter_hover transition"
              >
                Buy SLP
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Icon from '../components/app/Icon';
import MonitoringSettingsCase from '@/components/monitoring-settings/Case';
import { mapGetters } from 'vuex';

export default {
  name: 'MonitoringSettings',
  components: {
    Icon,
    MonitoringSettingsCase
  },
  data () {
    return {
      step: 1,
      period: '',
      profileChangesPrice: 0,
      friendsChangesPrice: 0,
      openCases: false,
      checkProfileInformationChanges: true,
      checkFriendsListChanges: false,
      selectedCase: null,
      caseFromParams: null,
      caseProfiles: [],
      checkedProfiles: [],
      pipelines: [],
      loadProfiles: false,
      lowSlpInfo: true
    };
  },
  provide () {
    return {
      addProfileForMonitoring: this.addProfileForMonitoring,
      removeProfileFromMonitoring: this.removeProfileFromMonitoring
    };
  },
  async mounted () {
    this.setPrices();
    this.setPeriod();
    this.setInputRangeFunctionality();
    this.getCaseFromParams();
    if (this.caseFromParams) {
      await this.openCaseProfiles(this.caseFromParams);
    }
  },
  computed: {
    ...mapGetters(['accountState', 'monitoringCases', 'monitoringOptions', 'monitoringLoadedProfiles', 'caseSearchesStorage']),
    totalPrice () {
      let price = 0;
      if (this.checkProfileInformationChanges) {
        price += this.profileChangesPrice;
      }
      if (this.checkFriendsListChanges) {
        price += this.friendsChangesPrice;
      }
      return price * this.checkedProfiles.length;
    },
    isCaseSelected () {
      return this.selectedCase || this.caseFromParams;
    },
    lowSlp () {
      return (this.accountState.balance < this.totalPrice);
    },
    editOneCase () {
      return this.caseFromParams && this.caseFromParams.tid;
    }
  },
  watch: {
    step () {
      this.setPeriod();
    }
  },
  methods: {
    setInputRangeFunctionality () {
      const inputRange = document.querySelector('input[type=range]');
      const rangeColorLeft = '#51AEE2';
      const rangeColorRight = '#bee0f4';
      inputRange.addEventListener('input', () => {
        if (inputRange.value === '0') {
          inputRange.value = this.step = 1;
          this.setPeriod();
        }
        const percent = inputRange.value * 25;
        const leftRange = `${rangeColorLeft} 0%, ${rangeColorLeft} ${percent}%`;
        const rightRange = `${rangeColorRight} ${percent}%, ${rangeColorRight} 100%`;
        inputRange.style.background = `linear-gradient(to right, ${leftRange}, ${rightRange})`;
      });
    },
    setPrices () {
      const transforms = this.accountState.transforms;
      this.profileChangesPrice = transforms.find(tr => tr.name === 'monitoring_linkedin_profile').price;
      this.friendsChangesPrice = transforms.find(tr => tr.name === 'facebook_friends').price;
    },
    getCaseFromParams () {
      const caseId = this.$route.params.case_id;
      const profileId = this.$route.params.profile_id;
      const taskId = this.$route.params.task_id;
      this.caseFromParams = caseId ? { oid: caseId, pid: profileId, tid: taskId } : null;
    },
    goBack () {
      this.$router.go(-1);
    },
    addProfileForMonitoring (profile) {
      this.checkedProfiles.push(profile);
    },
    removeProfileFromMonitoring (profile) {
      const index = this.checkedProfiles.findIndex(el => el.oid === profile.oid);
      this.checkedProfiles.splice(index, 1);
    },
    setPeriod () {
      const periods = ['12 hours', 'day', 'week', 'month'];
      this.period = periods[this.step - 1];
    },
    getRequestPeriod () {
      const periods = ['12h', '24h', '168h', '720h'];
      return periods[this.step - 1];
    },
    getPipelines (profile) {
      const pipelines = [];
      let option;
      if (this.checkProfileInformationChanges) {
        option = this.monitoringOptions.find(option => {
          return option.category === 'profile' && option.entity === profile.typeid;
        });
        pipelines.push(option.pipeline);
      }
      if (this.checkFriendsListChanges) {
        option = this.monitoringOptions.find(option => {
          return option.category === 'social_graph' && option.entity === profile.typeid;
        });
        pipelines.push(option.pipeline);
      }
      return pipelines;
    },
    async startMonitoring () {
      let payload;
      this.$store.commit('setSearching', true);
      this.$store.commit('setLoadUrl', true);
      if (this.editOneCase) {
        payload = {
          method: 'POST',
          url: '/api/monitoring/tasks/update',
          body: {
            case_id: this.caseFromParams.oid,
            task_id: this.caseFromParams.tid,
            period: this.getRequestPeriod(),
            paused: false
          }
        };
      } else {
        payload = {
          method: 'POST',
          url: '/api/monitoring/tasks/add',
          body: {
            case_id: this.selectedCase.oid,
            period: this.getRequestPeriod(),
            targets: []
          }
        };
        this.checkedProfiles.forEach(profile => {
          payload.body.targets.push({
            entity_id: profile.oid,
            pipelines: this.getPipelines(profile)
          });
        });
      }
      let routerInfo = { path: '/monitoring' };
      if (this.caseFromParams) routerInfo = { path: `/monitoring/review/${this.caseFromParams.oid}` };
      await this.$store.dispatch('ajaxWithTokenRefresh', payload);
      await this.$router.push(routerInfo);
    },
    async getProfiles (searchID) {
      const profiles = [];
      const payload = {
        method: 'POST',
        url: '/api/user/searches/nodes',
        body: {
          search_id: searchID,
          levels: [1],
          limit: 2000,
          offset: 0
        }
      };
      while (true) {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        profiles.push(...response.data);
        if (response.data.length < payload.body.limit) {
          this.$store.commit('keepMonitoringProfiles', { profiles, searchID });
          return profiles;
        }
        payload.body.offset += payload.body.limit;
      }
    },
    async openCaseProfiles (investigation) {
      this.loadProfiles = true;
      this.selectedCase = investigation;
      this.checkedProfiles = [];
      this.caseProfiles = [];
      const allProfiles = [];
      const caseID = investigation.oid;
      let searches = this.caseSearchesStorage[caseID];
      if (!searches) {
        searches = await this.$store.dispatch('ajaxWithTokenRefresh', {
          method: 'POST',
          url: '/api/user/reclst',
          body: {
            fid: caseID
          }
        });
        this.$store.commit('keepCaseSearches', { caseID, searches });
      }

      return Promise.all(searches.data.map(async search => {
        const profiles = await this.getProfiles(search.rid);
        allProfiles.push(...profiles.filter(ent => ent.typeid === 'maltego.linkedin.profile'));
      })).then(async () => {
        await this.$store.dispatch('convertEntityFieldsToMap', allProfiles);
        this.caseProfiles = [...allProfiles];
        this.loadProfiles = false;
      });
    },
    removeSelectedCase () {
      this.selectedCase = this.caseFromParams = null;
    }
  }
};
</script>

<style lang="scss">
  $light-grey: #F8FCFF;
  $font-grey: #A0B2BE;
  $grey: #8D8D8E;
  $dark-grey: #29292B;
  $blue: #51AEE2;
  $white: #FFFFFF;
  $border-radius: 5px;

  .pseudo-range {
    position: relative;
    margin: 21px 0 30px;

    .pseudo-body {
      position: relative;
      z-index: 1;
      height: 2px;
      background: #bee0f4;
      border-radius: 8px;
      margin: 0 10px;

      &:before {
        position: absolute;
        top: 0;
        left: -10px;
        right: -10px;
        bottom: 0;
        content: '';
        background: linear-gradient(to right, $blue 0%, $blue 25%, #bee0f4 25%, #bee0f4 100%);
      }

      .pseudo-line {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: $blue;
        transition: 0.15s;
      }

      .pseudo-bullet {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $blue;
        cursor: pointer;
        transition: 0.15s;
      }
    }

    & input[type=range] {
      position: absolute;
      top: 0;
      left: 0px;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(to right, $blue 0%, $blue 25%, #bee0f4 25%, #bee0f4 100%);
      border: 0 solid #000000;
      border-radius: 8px;
      height: 2px;
      width: 100%;
      outline: none;
      transition: background 450ms ease-in;
      -webkit-appearance: none;
      opacity: 0;
    }

    & input[type=range]::-webkit-slider-thumb {
      box-shadow: 0 0 0 #000000;
      border: 0 solid #000000;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $blue;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }

  @mixin fontStyles($fontSize, $lineHeight, $letterSpacing) {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize;
    line-height: $lineHeight;
    letter-spacing: $letterSpacing;
  }

  .monitoring {
     &__beta {
       background: $light-grey;
       border-radius: $border-radius;
       padding: 24px;
       &-title {
         @include fontStyles(16px, 24px, 0.04em);
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 4px;
         color: $blue;
       }
       &-desc {
         @include fontStyles(14px, 24px, 0.04em);
         color: $grey;
       }
     }
     &-settings {
       background: $white;
       padding: 24px;
       & h1 {
         @include fontStyles(22px, 32px, -0.02em);
         font-weight: 600;
         color: $dark-grey;
         margin-bottom: 24px;
       }
       & h2 {
         @include fontStyles(16px, 24px, 0.04em);
         color: $dark-grey;
       }
       &__cases {
         padding: 12px 0;
         background: #F1F8FB;
         border-bottom-right-radius: $border-radius;
         border-bottom-left-radius: $border-radius;
       }
       &__case {
         @include fontStyles(14px, 20px, 0.04em);
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding: 4px 16px;
         width: 100%;
         color: $grey;
         & div:last-child { color: $font-grey }
         &:hover {
           background: #EFF4F7;
           & div { color: #60BCE3 }
         }
       }
       &__input-block {
         color: $dark-grey;
         display: flex;
         align-items: center;
         margin-bottom: 12px;
         & label {
           @include fontStyles(14px, 24px, 0.04em);
         }
         & label:nth-child(2) {
           margin-right: 12px;
           margin-left: auto;
           color: $blue;
           min-width: 40px;
         }
       }
       &__range-title {
         margin-top: 24px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         & div {
           color: $font-grey;
           @include fontStyles(16px, 24px, 0.04em);
         }
       }
       &__footer {
         display: flex;
         align-items: center;
         justify-content: space-between;
         & p {
           @include fontStyles(20px, 24px, 0.08em);
           font-weight: 600;
           color: $font-grey;
         }
         & button {
           @include fontStyles(14px, 24px, 0.08em);
           padding: 12px;
           font-weight: 600;
           display: flex;
           align-items: center;
           text-align: center;
           min-width: 260px;
           margin-right: 12px;
         }
       }
       &__list {
         & button {
           @include fontStyles(14px, 24px, 0.04em);
           width: 100%;
           padding: 12px 23px 12px 16px;
           background: $light-grey;
           color: $grey;
           display: flex;
           align-items: center;
           justify-content: space-between;
         }
       }
     }
  }
  #start-monitoring {
    color: $white;
  }
  .cases-list {
    height: 342px;
    overflow-x: hidden;
  }
</style>
